.tour-columns ::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}

.tour-columns ::-webkit-scrollbar-track {
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.3);
}

.tour-columns ::-webkit-scrollbar-thumb {
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background: orange;
  border: 1px solid rgba(0, 0, 0, 0.8);
}

.tour-columns ::-webkit-scrollbar-thumb:window-inactive {
  background: orange;
}
