@import url(https://fonts.googleapis.com/css2?family=Nunito);
.tour-columns ::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}

.tour-columns ::-webkit-scrollbar-track {
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.3);
}

.tour-columns ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: orange;
  border: 1px solid rgba(0, 0, 0, 0.8);
}

.tour-columns ::-webkit-scrollbar-thumb:window-inactive {
  background: orange;
}

body {
  margin: 0;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

#root {
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* order details tab content padding */
.MuiBox-root {
  padding: 0;
}

.sr-only {
  display: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

@-webkit-keyframes createBox {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes createBox {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.MuiTableCell-head {
  text-transform: capitalize;
}

